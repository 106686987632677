<template>
        <div class="notifications-dropdown-list">
                <div class="notifications-dropdown-title">
                        {{ this.$i18n.locale == "ar" ? 'الإشعارات' : 'Notifications' }}
                        <router-link to="/profile/notifications" class="all-notifications">
                                {{ this.$i18n.locale == "ar" ? 'كافة الإشعارات' : 'All Notifications' }}
                        </router-link>
                </div>
                <ul class="notifications-dropdown-container">
                        <router-link :to="generateNotificationLink(notification)"  v-for="notification in notifications?.list">
                                <li :class="'notifications-dropdown-list-item ' + (notification.is_read == 0 ? 'new' : '')" >
                                        
                                        <svg v-if="notification.is_read == 0" class="envelop-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                                        
                                        <svg v-else class="envelop-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 208.1L256 65.9 448 208.1l0 47.4L289.5 373c-9.7 7.2-21.4 11-33.5 11s-23.8-3.9-33.5-11L64 255.5l0-47.4zM256 0c-12.1 0-23.8 3.9-33.5 11L25.9 156.7C9.6 168.8 0 187.8 0 208.1L0 448c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-239.9c0-20.3-9.6-39.4-25.9-51.4L289.5 11C279.8 3.9 268.1 0 256 0z"/></svg>
                                        
                                        <div class="notifications-item-body">
                                                <span class="notification-item-title">
                                                        {{ notification.title }}
                                                </span>
                                                <span class="notification-item-text">
                                                        {{ notification.body }}
                                                </span>
                                                <span class="notification-item-date">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
                                                        {{ notification.created_at }}
                                                </span>
                                        </div>
                                </li>
                        </router-link>
                </ul>
        </div>
</template>
<script>
      import Cookie from 'cookie-universal';
      
      export default {
        props: ['notifications'],
        data() {
          return {
            showPrompt: false,
            lang: Cookie().get("lang")
          }
        },
        methods: {
                generateNotificationLink(notification) {
                        switch(notification.contentable_type) {
                                case 'PackageRequest':
                                        return `/packages/order/details/${notification.contentable_id}`;
                                break;
                                case 'ServiceRequest':
                                        return `/services/order/details/${notification.contentable_id}`;
                                // code block
                                break;
                                case 'Consultation':
                                        return `/profile/consulting`;
                                break;
                                case 'Ticket':
                                        return `/profile/ticket/details/${notification.contentable_id}`;
                                break;
                                default:
                                // code block
                        }
                }
        },
        mounted() {
        },
      };
</script>

<style scoped>
</style>



